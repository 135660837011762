import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { Container, Row, Col } from 'react-bootstrap';

const Template = ({ data }) => {
  const { markdownRemark } = data;
  const { html } = markdownRemark;
  const seo = {
    title: null,
    description: null,
  };
  return (
    <Layout seo={seo}>
      <Container>
        <Row>
          <Col>
            <div
              className="md-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const dataQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
    }
  }
`;

export default Template;
